import axios from "axios"

export const sendMail = async (
  contactName: string,
  contactMail: string,
  contactMessage: string
) => {
  if (process.env.NODE_ENV !== "production") {
    await new Promise((resolve, reject) =>
      setTimeout(() => {
        if (Math.random() > 0.5) {
          resolve()
        } else {
          reject()
        }
      }, 2000)
    ) // fake API call for dev
  } else {
    await axios.post(process.env.REACT_APP_CONTACT_FORM_ENDPOINT || "", {
      contactEmail: contactMail,
      contactName: contactName,
      content: contactMessage,
    })
  }
}

export const Locale = {
  DE: "de",
  EN: "en",
}
