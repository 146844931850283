import styled, { css } from "styled-components"
import React from "react"
import { useIntl } from "gatsby-plugin-intl"

export const LearnMoreIndicator = () => {
  const { formatMessage } = useIntl()
  const learnMoreString = formatMessage({ id: "generic.learn-more" })
  return <Wrapper>{learnMoreString} -&gt;</Wrapper>
}

const Wrapper = styled.div`
  text-align: right;
  transition: transform 0.6s;
  font-weight: bold;
`

export const LearnMoreIndicatorStyles = css`
  &:hover ${Wrapper} {
    transform: translateX(5px);
  }
`
