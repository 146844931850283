import React, { ChangeEvent, FC, FormEvent, useState } from "react"
import {
  trackEvent,
  TrackingEventAction,
  TrackingEventCategory,
  TrackingEventLabel,
} from "../analytics"
import { sendMail } from "../helpers"
import styled, { css } from "styled-components"
import { color, fontFamilyDefault } from "../styles/theme"
import { SubmitButton } from "./button"
import { useIntl } from "gatsby-plugin-intl"
import FullWidth from "./layout/full-width"
import { LimitedWidth } from "./layout/limited-width"
import { clamp } from "../styles/helpers"
import { OneByOneGrid } from "./layout/grid"

export const ContactForm: FC<{ initialMessage?: string; title?: string }> = ({
  initialMessage = "",
  children,
  title,
}) => {
  const { formatMessage } = useIntl()
  const [contactName, setContactName] = useState("")
  const [contactMail, setContactMail] = useState("")
  const [contactMessage, setContactMessage] = useState(initialMessage)

  const [submitInProgress, setSubmitInProgress] = useState(false)
  const [submitSuccessful, setSubmitSuccessful] = useState<boolean | null>(null)

  const labelName = formatMessage({
    id: "section.contact.label.contact-name",
  })

  const labelMail = formatMessage({
    id: "section.contact.label.contact-mail",
  })

  const labelMessage = formatMessage({
    id: "section.contact.label.contact-message",
  })

  const labelSubmitButton = formatMessage({
    id: "section.contact.label.submit-button",
  })

  const messageSubmitSuccessful = formatMessage({
    id: "section.contact.submit-successful",
  })

  const messageSubmitFailed = formatMessage({
    id: "section.contact.submit-failed",
  })

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    trackEvent(
      TrackingEventAction.CLICK,
      TrackingEventCategory.CONTACT_FORM,
      TrackingEventLabel.SUBMIT_CONTACT_FORM
    )
    setSubmitInProgress(true)
    setSubmitSuccessful(null)
    try {
      await sendMail(contactName, contactMail, contactMessage)
      setContactName("")
      setContactMail("")
      setContactMessage("")
      setSubmitSuccessful(true)
    } catch (e) {
      setSubmitSuccessful(false)
    } finally {
      setSubmitInProgress(false)
    }
  }

  const submitDisabled =
    contactName === "" ||
    contactMail === "" ||
    contactMessage === "" ||
    submitInProgress

  return (
    <Wrapper id={"contact"}>
      <LimitedWidth>
        <Title>{title}</Title>
        <OneByOneGrid $alignTop>
          <div>{children}</div>
          <Form className="contact-form" onSubmit={onSubmit}>
            <FormGroup>
              <StyledLabel htmlFor="contact-name">{labelName}</StyledLabel>
              <StyledTextInput
                type="text"
                id="contact-name"
                disabled={submitInProgress}
                value={contactName}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setContactName(event.target.value)
                }}
                onFocus={() =>
                  trackEvent(
                    TrackingEventAction.INPUT_FIELD_ENTER,
                    TrackingEventCategory.CONTACT_FORM,
                    TrackingEventLabel.ENTER_CONTACT_FORM_FIELD,
                    "contact-name"
                  )
                }
              />
            </FormGroup>

            <FormGroup>
              <StyledLabel htmlFor="contact-mail">{labelMail}</StyledLabel>
              <StyledTextInput
                type="email"
                id="contact-mail"
                value={contactMail}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setContactMail(event.target.value)
                }}
                onFocus={() =>
                  trackEvent(
                    TrackingEventAction.INPUT_FIELD_ENTER,
                    TrackingEventCategory.CONTACT_FORM,
                    TrackingEventLabel.ENTER_CONTACT_FORM_FIELD,
                    "contact-mail"
                  )
                }
              />
            </FormGroup>

            <FormGroup>
              <StyledLabel htmlFor="contact-message">
                {labelMessage}
              </StyledLabel>
              <StyledTextarea
                id="contact-message"
                value={contactMessage}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                  setContactMessage(event.target.value)
                }}
                onFocus={() =>
                  trackEvent(
                    TrackingEventAction.INPUT_FIELD_ENTER,
                    TrackingEventCategory.CONTACT_FORM,
                    TrackingEventLabel.ENTER_CONTACT_FORM_FIELD,
                    "contact-message"
                  )
                }
              />
            </FormGroup>

            <SubmitButton
              type="submit"
              disabled={submitDisabled}
              value={labelSubmitButton}
            />

            {submitSuccessful === true && (
              <SubmitSuccessful>{messageSubmitSuccessful}</SubmitSuccessful>
            )}
            {submitSuccessful === false && (
              <SubmitFailed>{messageSubmitFailed}</SubmitFailed>
            )}
          </Form>
        </OneByOneGrid>
      </LimitedWidth>
    </Wrapper>
  )
}

const Wrapper = styled(FullWidth)`
  display: grid;
  grid-row-gap: 0.5rem;
  background: ${({ theme }) => theme.color.lightgray};
  padding: ${clamp(2, 4)} 0;
`

const Form = styled.form``

const StyledLabel = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 0.5em;
`

const FormGroup = styled.div`
  &:focus-within ${StyledLabel} {
    color: ${({ theme }) => theme.semanticColor.primary};
  }
`

const commonInputStyled = css`
  ${fontFamilyDefault};
  border: 2px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  width: 100%;
  background: ${color.lightgray};
  border-radius: 3px;
  padding: 0.5rem;
  font-size: 0.9rem;
  background: white;

  &:disabled {
    opacity: 0.5;
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.semanticColor.primary};
  }
`

const Title = styled.h2`
  margin-top: 0;
`

const commonFeedbackStyles = css`
  margin-top: 1rem;
  font-weight: bold;
`
const SubmitSuccessful = styled.div`
  ${commonFeedbackStyles};
  color: ${color.green};
`

const SubmitFailed = styled.div`
  ${commonFeedbackStyles};
  color: ${color.red};
`

const StyledTextInput = styled.input.attrs(({ type }) => ({
  type,
}))`
  ${commonInputStyled};
`

const StyledTextarea = styled.textarea`
  ${commonInputStyled};
  min-height: 5rem;
  max-height: 10rem;
`
