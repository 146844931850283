import React, { FC } from "react"
import styled from "styled-components"
import { clamp } from "../styles/helpers"

type Props = {
  title?: string
  id: string
}

const StyledSection = styled.section`
  margin-bottom: ${clamp(4, 8)};
`

const StyledHeading = styled.h2`
  font-size: ${clamp(1.5, 3)};
  margin-bottom: ${clamp(2, 4)};
  text-align: center;
  position: relative;
  letter-spacing: -0.02em;
`

const ContentSection: FC<Props> = ({ title, id, children }) => {
  return (
    <StyledSection id={id}>
      {title && <StyledHeading>{title}</StyledHeading>}
      {children}
    </StyledSection>
  )
}

export default ContentSection
