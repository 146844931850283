import React, { FC } from "react"
import styled, { css } from "styled-components"
import { darken, lighten } from "polished"

type Props = {
  inverted: boolean
}

const Button: FC<Props> = ({ inverted, children }) =>
  inverted ? (
    <InvertedButton>{children}</InvertedButton>
  ) : (
    <DefaultButton>{children}</DefaultButton>
  )

export default Button

export const buttonBaseStyles = () => css`
  padding: 1em 2em;
  cursor: pointer;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: bold;

  border: none;
  transition: background 0.3s;

  &:focus {
    outline: 0;
  }
`

const DefaultButton = styled.button`
  ${buttonBaseStyles};
  background: ${({ theme }) => theme.semanticColor.primary};
  color: ${({ theme }) => theme.semanticColor.secondary};
  

  &:hover, &:focus {
    background: ${({ theme }) => lighten(0.1, theme.semanticColor.primary)};
`

export const SubmitButton = styled.input.attrs({ type: "submit" })`
  ${buttonBaseStyles};
  background: ${({ theme }) => theme.semanticColor.primary};
  color: ${({ theme }) => theme.semanticColor.secondary};

  &:hover, &:focus {
    background: ${({ theme }) => lighten(0.1, theme.semanticColor.primary)};
`

const InvertedButton = styled.button`
  ${buttonBaseStyles};
  background: ${({ theme }) => theme.semanticColor.secondary};
  color: ${({ theme }) => theme.semanticColor.primary};

  &:hover,
  &:focus {
    background: ${({ theme }) => darken(0.1, theme.semanticColor.secondary)};
  }
`
