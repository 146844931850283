import React, { FC } from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  LearnMoreIndicator,
  LearnMoreIndicatorStyles,
} from "./learn-more-indicator"
import styled from "styled-components"
import { UnstyledLink } from "./unstyled-link"
import { clamp } from "../styles/helpers"
import { boxShadowDefault } from "../styles/theme"
import media from "styled-media-query"
import { Tag } from "./tag"

type Props = {
  logo?: any
  id: string
  url: string
}

export const ReferenceCard: FC<Props> = ({ id, url, logo }) => {
  const { formatMessage } = useIntl()
  const title = formatMessage({ id: `reference.${id}.title` })
  const industry = formatMessage({ id: `reference.${id}.industry` })
  const teaser = formatMessage({ id: `reference.${id}.seoDescription` })

  const logoImage = getImage(logo)

  return (
    <ReferenceLink to={url}>
      <CardContent>
        {logo && (
          <LogoContainer
            $logoWidth={logoImage!.width}
            $logoHeight={logoImage!.height}
          >
            <GatsbyImage image={logoImage!} alt="" />
          </LogoContainer>
        )}
        <Tag>
          <FormattedMessage id="reference.industry" />: {industry}
        </Tag>
        <CardTitle>{title}</CardTitle>
        <ReferenceMeta>{teaser}</ReferenceMeta>
        <LearnMoreIndicator />
      </CardContent>
    </ReferenceLink>
  )
}

const ReferenceLink = styled(UnstyledLink)`
  position: relative;
  background: white;
  padding: ${clamp(1.5, 2)};
  border-top: 10px solid ${({ theme }) => theme.semanticColor.primary};
  transition: box-shadow 0.3s;
  ${boxShadowDefault}
  ${LearnMoreIndicatorStyles}
  height: 100%;
  border-radius: 5px;
`

const CardContent = styled.div`
  position: relative;
  display: grid;
  align-items: center;
  height: 100%;
`

const CardTitle = styled.h3`
  font-size: ${clamp(1.5, 2)};
  margin: 0 0 1rem 0;
  line-height: 1;
  max-width: 80%;
`

const LogoContainer = styled.div<{ $logoWidth: number; $logoHeight: number }>`
  position: absolute;
  width: ${({ $logoWidth, $logoHeight }) =>
    60 * Math.sqrt($logoWidth / $logoHeight)}px;
  height: ${({ $logoWidth, $logoHeight }) =>
    (60 * Math.sqrt($logoWidth / $logoHeight)) / ($logoWidth / $logoHeight)}px;

  right: 0;
  top: 0;

  ${media.lessThan("medium")`
    position: static;
    margin: 0 auto 1rem;
  `}
`

const ReferenceMeta = styled.div`
  margin-bottom: 1em;
`
