import React, { FC } from "react"
import ContentSection from "../content-section"
import { graphql, useStaticQuery } from "gatsby"
import { OneByOneGrid } from "../layout/grid"
import { ReferenceCard } from "../reference-card"

type Props = {
  ids: Array<string>
}

const ReferencesSection: FC<Props> = ({ ids }) => {
  const showReference = (id: string) => ids.indexOf(id) > -1

  const { rioLogo, hoermannLogo, replyLogo } = useStaticQuery(
    graphql`
      query {
        rioLogo: file(relativePath: { eq: "logos/rio-logo.png" }) {
          ...DefaultImageOptions
        }
        hoermannLogo: file(relativePath: { eq: "logos/hoermann-logo.png" }) {
          ...DefaultImageOptions
        }
        replyLogo: file(relativePath: { eq: "logos/reply-logo.png" }) {
          ...DefaultImageOptions
        }
      }
    `
  )

  return (
    <ContentSection id={"references"}>
      <OneByOneGrid>
        {showReference("data-driven-experimentation") && (
          <ReferenceCard
            id={"data-driven-experimentation"}
            url={"/references/data-driven-experimentation"}
            logo={rioLogo}
          />
        )}
        {showReference("10-day-cloud-onboarding") && (
          <ReferenceCard
            id={"10-day-cloud-onboarding"}
            url={"/references/10-day-cloud-onboarding"}
            logo={hoermannLogo}
          />
        )}
        {showReference("serverless-backend") && (
          <ReferenceCard
            id={"serverless-backend"}
            url={"/references/serverless-backend"}
            logo={rioLogo}
          />
        )}
        {showReference("new-application") && (
          <ReferenceCard
            id={"new-application"}
            url={"/references/new-application"}
            logo={replyLogo}
          />
        )}
        {showReference("microfrontends") && (
          <ReferenceCard
            id={"microfrontends"}
            url={"/references/microfrontends"}
          />
        )}
      </OneByOneGrid>
    </ContentSection>
  )
}

export default ReferencesSection
